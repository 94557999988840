import { ErrorHandler, Injectable } from '@angular/core';
import { CustomError } from '../models/class/custom-error';
import { environment } from 'src/environments/environment';
import { ErrorResponse, Response } from 'libraries/AonSDK/src/aon';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandlerService implements ErrorHandler{

  constructor(public errorService: ErrorService, private router: Router, public authService: AuthService ) {
  }

  handleError(error: any): void {
    if(environment.production === false){
      console.log('%c'+error, "color:red");
      if (error instanceof Error) {
        console.log('%cError name:', "color:red", error.name);
        console.log('%cError message:', "color:red", error.message);
        console.log('%cError stack trace:', "color:red", error.stack);
      }
    }
    if (error.promise && error.rejection) {
      this.manageError(error.rejection);
      this.launchError(error.rejection);
    } else {
      this.manageError(error);
      this.launchError(error);
    }
  }

  launchError(errorList: any){
    if(errorList instanceof CustomError || errorList instanceof Response || errorList instanceof ErrorResponse)
      this.errorService.displayError(errorList);
    else if (typeof errorList === 'string')
      this.errorService.displayErrorText(errorList);
  }

  manageError(error: any){
    if(error.code === '0125'){
      this.authService.logout();
    }
  }

}
